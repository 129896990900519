import * as React from 'react';
import { graphql } from "gatsby";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import StaticPage from '../components/StaticPage/StaticPage';

type Props = {
  data: {
    aboutUsData: {
      header: string,
      content: {
        raw: string,
      },
      photos: {
        file: {
          url: string
        }
      }[]
    }
  }
}

export const query = graphql`
  query($locale: String) {
    aboutUsData: contentfulStaticPage(
      node_locale: { eq: $locale }
      slug: { eq: "about" }
    ) {
      header
      content {
        raw
      }
      photos {
        file {
          url
        }
      }
    }
  }
`

const About: React.FC<Props> = ({data: { aboutUsData }}: Props) => 
  <StaticPage header={aboutUsData.header} description={documentToHtmlString(JSON.parse(aboutUsData.content.raw))} images={aboutUsData.photos} />

export default About
